import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'MypageNavigation';

export default defineMessages({
  myPageTitle: {
    id: `${I18N_NAMESPACE}.myPageTitle`,
    defaultMessage: 'My page',
  },

  myPageTitleTemplate: {
    id: `${I18N_NAMESPACE}.myPageTitleTemplate`,
    defaultMessage: 'My page: %s',
  },

  myPageOfflineMessage: {
    id: `${I18N_NAMESPACE}.myPageOfflineMessage`,
    defaultMessage: 'Offline',
  },

  myPageOfflineDescription: {
    id: `${I18N_NAMESPACE}.myPageOfflineDescription`,
    defaultMessage:
      'We lost contact, could it be that something is wrong with your Internet connection?',
  },

  sectionHeadingSolar: {
    id: `${I18N_NAMESPACE}.sectionHeadingSolar`,
    defaultMessage: 'Your solar cell device',
  },

  sectionContentSolar: {
    id: `${I18N_NAMESPACE}.sectionContentSolar`,
    defaultMessage:
      'Below you will see an overview of your solar cell device in {content}',
  },

  sectionContentProject: {
    id: `${I18N_NAMESPACE}.sectionContentProject`,
    defaultMessage:
      'Below you will see the project status of ongoing installation in {content}.',
  },

  sectionHeadingGrid: {
    id: `${I18N_NAMESPACE}.sectionHeadingGrid`,
    defaultMessage: 'Neighbour Power',
  },

  sectionContentGrid: {
    id: `${I18N_NAMESPACE}.sectionContentGrid`,
    defaultMessage:
      'Below you will see an overview of your Neighbour Power subscriptions.',
  },

  overviewPageName: {
    id: `${I18N_NAMESPACE}.overviewPageName`,
    defaultMessage: 'Overview',
  },

  economyPageName: {
    id: `${I18N_NAMESPACE}.economyPageName`,
    defaultMessage: 'Billing details',
  },

  accountPageName: {
    id: `${I18N_NAMESPACE}.accountPageName`,
    defaultMessage: 'Account',
  },
  contactPageName: {
    id: `${I18N_NAMESPACE}.contactPageName`,
    defaultMessage: 'Contact',
  },
  editAccount: {
    id: `${I18N_NAMESPACE}.editAccount`,
    defaultMessage: 'Edit account',
  },
  logout: {
    id: `${I18N_NAMESPACE}.logout`,
    defaultMessage: 'Log out',
  },

  noProductsPageTitle: {
    id: `${I18N_NAMESPACE}.noProductsPageTitle`,
    defaultMessage: 'Overview',
  },

  noProductsPageDescription: {
    id: `${I18N_NAMESPACE}.noProductsPageDescription`,
    defaultMessage:
      'Through My page you can exercise complete control over both usage- and production data',
  },

  noProductsPageHeading: {
    id: `${I18N_NAMESPACE}.noProductsPageHeading`,
    defaultMessage: 'Welcome to My page',
  },

  noProductsPageParagraph1: {
    id: `${I18N_NAMESPACE}.noProductsPageParagraph1`,
    defaultMessage:
      'We cannot find any active product from our end attached to your account.',
  },

  noProductsPageParagraph2: {
    id: `${I18N_NAMESPACE}.noProductsPageParagraph2`,
    defaultMessage:
      'If you have ordered products from us, the information on installation planning will show up immediately on this page.',
  },

  overviewPageDescription: {
    id: `${I18N_NAMESPACE}.overviewPageDescription`,
    defaultMessage:
      'Through My side you can get a complete overview over usage and production of power by your device',
  },

  overviewLoadingMessage: {
    id: `${I18N_NAMESPACE}.overviewLoadingMessage`,
    defaultMessage: 'Get information about your products',
  },

  greetingHeader: {
    id: `${I18N_NAMESPACE}.greetingHeader`,
    defaultMessage: 'Keep track with the Otovo app',
  },

  greetingDescription: {
    id: `${I18N_NAMESPACE}.greetingDescription`,
    defaultMessage: 'You have {customerRelationDescription} with Otovo.',
  },

  greetingGridSubscriptions: {
    id: `${I18N_NAMESPACE}.greetingGridSubscriptions`,
    defaultMessage:
      '{numSubscriptions, plural, one {ett} other {{numSubscriptions} }} Neighbour Power subscription',
  },

  greetingInstallations: {
    id: `${I18N_NAMESPACE}.greetingInstallations`,
    defaultMessage:
      '{numInstallations, plural, one {one installation} other {{numInstallations} installations}}',
  },

  greetingProjects: {
    id: `${I18N_NAMESPACE}.greetingProjects`,
    defaultMessage:
      '{numProjects, plural, one {one ongoing project} other {{numProjects} ongoing projects}}',
  },

  greetingDescriptionLastSeparator: {
    id: `${I18N_NAMESPACE}.greetingDescriptionLastSeparator`,
    defaultMessage: 'and',
  },

  greetingDescriptionWithMultipleEntries: {
    id: `${I18N_NAMESPACE}.greetingDescriptionWithMultipleEntries`,
    defaultMessage: 'Below you will find information on each of them.',
  },

  downloadAppText: {
    id: `${I18N_NAMESPACE}.downloadAppText`,
    defaultMessage:
      'Download our app to follow your installation process and view your solar production',
  },

  installationSectionHeading: {
    id: `${I18N_NAMESPACE}.installationSectionHeading`,
    defaultMessage:
      '{numInstallations, plural, one {Your installation} other {Your installations}}',
  },

  contactUsQuestion: {
    id: `${I18N_NAMESPACE}.contactUsQuestion`,
    defaultMessage: 'Still unsure about something? ',
  },

  contactUs: {
    id: `${I18N_NAMESPACE}.contactUs`,
    defaultMessage: 'Contact Us',
  },
  submitRequestTitle: {
    id: `${I18N_NAMESPACE}.submitRequestTitle`,
    defaultMessage: 'Submit a support request',
  },
  submitRequestDescription: {
    id: `${I18N_NAMESPACE}.submitRequestDescription`,
    defaultMessage:
      'Please fill out the following form to contact our support team.',
  },
  welcome: {
    id: `${I18N_NAMESPACE}.welcome`,
    defaultMessage: 'Welcome, {name}',
  },

  support: {
    id: `${I18N_NAMESPACE}.support`,
    defaultMessage: 'Support',
  },
  helpCenter: {
    id: `${I18N_NAMESPACE}.helpCenter`,
    defaultMessage: 'Help Center',
  },

  projectDelayedTitle: {
    id: `${I18N_NAMESPACE}.projectDelayedTitle`,
    defaultMessage: 'We are experiencing a delay.',
  },

  projectDelayedText: {
    id: `${I18N_NAMESPACE}.projectDelayedText`,
    defaultMessage:
      "This is taking longer than expected, but we're doing everything we can to get the project moving faster.",
  },
  contactInformationHeading: {
    id: `${I18N_NAMESPACE}.contactInformationHeading`,
    defaultMessage: 'Contact information',
  },
  contactInformationDescription: {
    id: `${I18N_NAMESPACE}.contactInformationDescription`,
    defaultMessage:
      "We'll contact you about your installation using these details.",
  },
  projectNumber: {
    id: `${I18N_NAMESPACE}.projectNumber`,
    defaultMessage: 'Project number',
  },
});
